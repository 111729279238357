import dda_logo_white from "../images/dda_logo_white.svg";

export default function Footer(props) {
  return (
    <footer className="footer">
      <div className="container">
        <div className="row">
          <div className="col-lg-4 col-md-4 col-sm-12 col-12">
            <div className="row mt-5">
              <div className="col-12">
                <h5 style={{ color: "#fff", textAlign: "left" }}>
                  <strong>DDA-Geschäftsstelle</strong>
                </h5>
                <small>
                  An den Speichern 2 <br />
                  D-48157 Münster <br />
                </small>
                <small>
                  <span className="icon phone"></span>0251 - 210 140 0 <br />
                  <span className="icon envelope"></span>
                  <a href="mailto:info@dda-web.de">info@dda-web.de</a>
                  <br />
                </small>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-12 col-12">
            {/* TODO: Convert 'includes.spendenkonto' into a React component or other JSX-friendly inclusion */}
          </div>
          <div className="col-lg-4 col-md-4 col-sm-12 col-12">
            <div className="row mt-5">
              <div className="col-12">{/* Add content here */}</div>
            </div>
          </div>
        </div>
        <hr />
        <div className="row">
          <div className="col-4">
            <a
              href="https://www.dfg.de/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={dda_logo_white} width="100" />
            </a>
          </div>
          <div className="col-8">
            <small>
              Der DDA ist als gemeinnützige Organisation unter der Steuernummer
              336/5754/4653 von der Körperschafts- und Gewerbesteuer befreit.
              Datum des Freistellungsbescheides des Finanzamtes
              Münster-Innenstadt ist der 11.07.2023). Spenden an den DDA sind
              steuerlich absetzbar.
            </small>
          </div>
        </div>
        <hr />
        <div className="row">
          <div className="col-12 text-right">
            <small>
              <a href="https://www.dda-web.de/dsgvo">
                Datenschutz
                <sup className="icon anchor"></sup>
              </a>
              &nbsp;&nbsp; | &nbsp;&nbsp;
              <a href="https://www.dda-web.de/impressum">
                Impressum
                <sup className="icon anchor"></sup>
              </a>
            </small>
          </div>
        </div>
      </div>
    </footer>
  );
}
