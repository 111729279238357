import logo from "../images/dda_logo_white.svg";
import React from "react";

export default function NavBar(props) {
  const [navCollapsed, setNavCollapsed] = React.useState(true);

  function toogleNav() {
    setNavCollapsed(!navCollapsed);
  }

  return (
    <nav
      class="navbar navbar-expand-lg navbar-light fixed-top navbar-shrink navbar-shrink-content"
      id="mainNavContent"
    >
      <div class="container">
        <a
          class="navbar-brand "
          href="/"
          style={{ background: `url(${logo}) no-repeat center center` }}
        >
          <h1></h1>
        </a>
        <button class="navbar-toggler" type="button" onClick={toogleNav}>
          <span class="navbar-toggler-icon"></span>
        </button>
        <div
          className={
            "collapse navbar-collapse d-lg-flex flex-lg-row-reverse " +
            (navCollapsed ? "" : "show")
          }
          id="navbarResponsive"
        >
          <ul class="navbar-nav ml-auto">
            <li class="nav-item">
              <a class="nav-link" href="https://www.dda-web.de/">
                DDA
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}
